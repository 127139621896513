<template>
  <v-container>
    <v-layout>
      <h1>{{ trl("CLIENTS_READ_menu") }}</h1>
    </v-layout>

     <v-progress-linear
      v-if="loading"
      indeterminate
      rounded
      color="primary"
    ></v-progress-linear>
    <div v-else>
    <v-row class="mt-3">
      <v-col class="pb-0">
        <v-tabs fixed-tabs v-model="tabs.tab" class="ma-0">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab v-for="(item, index) in tabs.items" :key="index" @change="$refs.search.focus()">
            {{ item }}
          </v-tab>
        </v-tabs>
      </v-col>
      <v-spacer></v-spacer>

      <v-col class="pb-0">
        <v-text-field
          class="mt-n1"
          ref="search"
          v-model.trim="search"
          clearable
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          autofocus
        ></v-text-field>
      </v-col>
    </v-row>

    <v-tabs-items v-model="tabs.tab" class="px-6 py-2 mb-12">
      <v-tab-item>
        <v-card-title>
          {{ forecast.title }}
          : {{ numClients.fc }} Kunden
        </v-card-title>
        <v-card-text :class="{ 'three-col': filteredClients.fc.length > 5 }">
          <v-list>
            <v-list-item
              v-for="client in filteredClients.fc"
              :key="client.id"
              two-line
            >
              <v-list-item-content>
                <v-list-item-title
                  :title="`Client ID: ${client.id}`"
                  v-html="client.name"
                ></v-list-item-title>
                <v-list-item-subtitle>{{
                  smartjoin(client.divisions, trl("and"))
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-title>{{ numerus('? Kunde|n', numClients.new) }}</v-card-title>
        <v-card-text>
          Diese Kunden wurden in den letzten 3 Monaten angelegt:
          <v-list :class="{ 'three-col': filteredClients.fc.length > 5 }">
            <v-list-item v-for="client in filteredClients.new" :key="client.id">
              <v-list-item-content>
                <v-list-item-title
                  :title="`Client ID: ${client.id}`"
                  v-html="client.name"
                ></v-list-item-title>
                <v-list-item-subtitle>
                  {{ d2s(client.created_at, "dateshort") }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-title> {{ numClients.all }} Kunden</v-card-title>
        <v-card-text>
          Die Kundenliste wird stündlich (immer 15 Min nach der vollen Stunde)
          aktualisiert und enthält alle Kunden aus DAP Medialine sowie in
          TickTack angelegte Projektkunden.<br />
          Nur dort aktive Kunden (hier mit markierter Checkbox dargestellt)
          lassen sich für neue Kampagnen innerhalb von Forecast III nutzen. Die
          manuelle Bearbeitung von Kunden in Forecast III ist nicht möglich.
        </v-card-text>
        <ul :class="{ 'three-col': filteredClients.all.length > 5 }">
          <li v-for="item in filteredClients.all" :key="item.id" :class="{ 'is-child': item.parent_id }" :title="`Client ID: ${item.id}`">
            <v-icon color="primary" class="disabled" dense
              >mdi-checkbox-{{
                item.active ? "marked" : "blank-outline"
              }}</v-icon
            ><span v-html="item.name"></span>
          </li>
        </ul>
      </v-tab-item>
    </v-tabs-items>
    <v-banner v-if="clients.length === 0" rounded>{{
      trl("tenant_no_clients", current.name)
    }}</v-banner>
    </div>
  </v-container>
</template>

<script>
import { numerus, trl, smartjoin, getDivisionName, strellipsis } from "@/utils/strings";
import {
  getClientName,
  getClientPath,
  getClientGroup,
  getClientFamily,
} from "@/utils/clients";
import { d2s } from "@/utils/dates";
import { mapGetters } from "vuex";
import store from "@/store";
import { DateTime } from "luxon";

export default {
  data() {
    return {
      title: "Clients",
      loading: true,
      search: "",
      selected: {
        forecast: {},
      },
      tabs: {
        tab: 0,
        items: ["Kunden im Forecast", "neue Kunden", "alle Kunden"],
      },
      forecastClients: [],
      newClients: [],
      allClients: [],
    };
  },
  computed: {
    filteredClients() {
      let q = (this.search || "").toLowerCase();
      return {
        fc: this.forecastClients.filter((c) => c.search.includes(q)),
        new: this.newClients.filter((c) => c.search.includes(q)),
        all: this.allClients.filter((c) => c.search.includes(q)),
      };
    },
    numClients() {
      return {
        fc:
          this.forecastClients.length === this.filteredClients.fc.length
            ? this.forecastClients.length
            : `"${this.search}" » ` +
              this.filteredClients.fc.length +
              "/" +
              this.forecastClients.length,
        new:
          this.newClients.length === this.filteredClients.new.length
            ? this.newClients.length
            : `"${this.search}" » ` +
              this.filteredClients.new.length +
              "/" +
              this.newClients.length,
        all:
          this.allClients.length === this.filteredClients.all.length
            ? this.allClients.length
            : `"${this.search}" » ` +
              this.filteredClients.all.length +
              "/" +
              this.allClients.length,

      };
    },
    ...mapGetters([
      "current",
      "clients",
      "clientsWithProjects",
      "divisions",
      "forecasts",
    ]),
  },
  methods: {
    numerus,
    trl,
    smartjoin,
    strellipsis,
    d2s,
    getDivisionName,
    getClientName,
    getClientPath,
    getClientGroup,
    async init() {

      await this.$store.dispatch("req", { route: "clients", mutation: "clients"});
      this.loading = false;

      this.allClients = JSON.parse(JSON.stringify(this.clients));
      this.allClients.forEach(c => {
        c.name = getClientPath(c.id, true);
        c.search = getClientPath(c.id).toLowerCase();
      })

      let fc_clients = [];
      this.clientsWithProjects.forEach((c) => {
        let divisions = [...new Set(c.divisions)]
          .map((d) => getDivisionName(d))
          .sort((a, b) => a.localeCompare(b));
        let search = [getClientPath(c.id)].concat(divisions);

        fc_clients.push({
          id: c.id,
          name: getClientPath(c.id),
          divisions: divisions,
          search: search.join(" ").toLowerCase(),
        });
      });

      this.forecastClients = fc_clients.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      //await this.$store.dispatch("req", {method: "GET", route: "nb", mutation: "newBusiness"});

     

      // await this.$store.dispatch("req", {
      //   method: "GET",
      //   route: "clients",
      //   mutation: "clients",
      // });

      this.newClients = this.allClients.filter((c) => DateTime.fromSQL(c.created_at) > DateTime.now().minus({ months: 3 }));

      this.newClients.sort((a, b) => a.search.localeCompare(b.search));
      this.allClients.sort((a, b) => a.search.localeCompare(b.search));

    },
  },
  created() {
    this.forecast = this.forecasts.find(
      (f) => f.id === this.$store.state.activeForecastId
    );
    this.init();
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.current.permissions.CLIENTS_READ) {
      next();
    } else {
      next({ name: "NotFound" });
    }
  },
};
</script>

<style lang="scss" scoped>
ul {
  margin: 0;

  li {
    display: block;
  }
}

.disabled{
  opacity:.5;
}

.three-col {
  column-count: 3;
  break-inside: avoid-column;
 
  li.is-child{
    margin-left: 1.25em;

    ::v-deep span.parent{
      display: none;
    }
  }
}

.two-col {
  column-count: 2;
  break-inside: avoid-column;
 
  li.is-child{
    margin-left: 1.25em;

    ::v-deep span.parent{
      display: none;
    }
  }
}

::v-deep .parent {
  opacity: 0.6;
}


</style>