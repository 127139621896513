import store from '../store'


function getClientName(id) {
    return store.getters.clients.find((item) => item.id === id)?.name || `UNKNOWN CLIENT (${id})`;
}

function getClientPath(id, html) {
    let p = getClientParent(id);
    if (p === id) return getClientName(id);
     
    return html ? '<span class="parent">' + getClientName(p) + " » </span>" + getClientName(id) : getClientName(p) + " » " + getClientName(id)
}

function getClientGroup(cid) {
    let c = store.getters.clients.find(c => c.id === cid);
    if (!c) return cid;
    return c.parent_id || cid;
}

function getClientParent(client_id){
    let p, res = client_id;
    
    while (p = store.getters.clients.find((item) => item.id === res).parent_id) {
        res = p;
    }

    return res;
}

function getClientChildren(client_id) {
    return store.getters.clients.filter((item) => item.parent_id === client_id).map(x => x.id);
}

function getClientFamily(client_id) {
    let family = [getClientParent(client_id)];
    return family.concat(getClientChildren(family[0]));
}

export { getClientName, getClientPath, getClientGroup, getClientParent, getClientChildren, getClientFamily };