import { render, staticRenderFns } from "./Clients.vue?vue&type=template&id=70f8579a&scoped=true"
import script from "./Clients.vue?vue&type=script&lang=js"
export * from "./Clients.vue?vue&type=script&lang=js"
import style0 from "./Clients.vue?vue&type=style&index=0&id=70f8579a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f8579a",
  null
  
)

export default component.exports